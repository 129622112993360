import React, { useEffect } from "react";
import { Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();

  //Form Submit
  const submitHandler = async (values) => {
    try {
      const { data } = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/users/login",
        values
      );
      message.success("Login Success");
      localStorage.setItem(
        "user",
        JSON.stringify({ ...data.user, password: " " })
      );
      navigate("/home");
    } catch (error) {
      message.error("Invalid Something Went Wrong");
    }
  };

  //Prevent for login user
  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/home");
    }
  }, [navigate]);

  return (
    <>
      <div className="login-content">
        <div className="login-page">
          <h1>Admin Panel</h1>
          <Form
            layout="vertical"
            onFinish={submitHandler}
            className="login-form"
          >
            <h3>Login</h3>
            <Form.Item name="email">
              <Input type="email" placeholder="Email Address" />
            </Form.Item>
            <Form.Item name="password">
              <Input type="password" placeholder="Password" />
            </Form.Item>
            <Form.Item className="d-flex justify-content-between">
              <button className="login-button">Login</button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
