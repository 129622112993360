import React, { useEffect, useState, useRef, Children } from "react";
import axios from "axios";
import deleteicon from "../Assests/delete.png";
import Layout from "../components/Layout";
import { message } from "antd";

const Home = () => {
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null); // Initialize fileInputRef1 with useRef
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  //about company section
const [companydescription, setcompanyDescription] = useState("");
const [companylogo, setCompanyLogo] = useState([]);
const [companyerrors, setCompanyErrors] = useState({ description: "", logo: "" });

useEffect(() => {
  fetchcompany(); // Fetch data when component mounts
}, []); // Empty dependency array to only run once

const companydescriptionChange = (e) => {
  setcompanyDescription(e.target.value);
};

const companyupload = async (e) => {
  e.preventDefault();

  if (!companydescription.trim() || !fileInputRef1.current.files || fileInputRef1.current.files.length === 0) {
    setCompanyErrors({
      description: !companydescription.trim() ? "Company description is required" : "",
      logo: !fileInputRef1.current.files || fileInputRef1.current.files.length === 0 ? "Company logo is required" : "",
    });
    return;
  }

  const companyformData = new FormData();
  companyformData.append("description", companydescription);
  companyformData.append("logo", fileInputRef1.current.files[0]);

  try {
    const response = await axios.post(
      "https://api-prashant.we-matter.xyz/api/v1/company/add-company-data",
      companyformData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(companyformData)
    if (response.status === 201) {
      console.log("Data saved successfully!");
      fetchcompany();
      message.success("Company data added successfuly");
      setcompanyDescription("");
      fileInputRef1.current.value = ""; // Clear file input
      setCompanyErrors({ description: "", logo: "" });
    } else {
      console.log(companyformData)
      console.error("Failed to save data");
      message.error("Failed to add company data");
    }
  } catch (error) {
    console.log(companyformData)
    console.error("Error:", error);
  }
};


const fetchcompany = async () => {
  try {
    const response = await axios.get(
      "https://api-prashant.we-matter.xyz/api/v1/company/fetch-company-data"
    );
    setCompanyLogo(response.data);
  } catch (error) {
    console.error("Error fetching consulting data:", error);
  }
};

const companydelete = async (id) => {
  try {
    const response = await axios.delete(
      `https://api-prashant.we-matter.xyz/api/v1/company/delete-company-data/${id}`
    );
    if (response.status === 200) {
      console.log("Data deleted successfully!");
      message.success("Company data deleted successfully");
      fetchcompany(); // Fetch updated data after deletion
    } else {
      console.error("Failed to delete data");
      message.error("Failed to delete company data");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
  

  // slider section connection
  const [slidertitle, setsliderTitle] = useState("");
  const [sliderimage, setSliderImage] = useState([]);
  const [slidererrors, setSliderErrors] = useState({ title: "", img: "" });

  useEffect(() => {
    fetchslider(); // Fetch data when component mounts
  }, []); // Empty dependency array to only run once

  const sliderTitleChange = (e) => {
    setsliderTitle(e.target.value);
  };

  const sliderupload = async (e) => {
    e.preventDefault();

    if (!slidertitle.trim() || !fileInputRef2.current.files[0]) {
      setSliderErrors({
        title: !slidertitle.trim() ? "Title is required" : "",
        img: !fileInputRef2.current.files[0] ? "sliderimage is required" : "",
      });
      return;
    }

    const sliderformData = new FormData();
    sliderformData.append("title", slidertitle);
    sliderformData.append("image", fileInputRef2.current.files[0]);

    try {
      const response = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/homeslider/add-home-slider",
        sliderformData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        console.log("Data saved successfully!");
        fetchslider();
        message.success("Slider added successfuly");
        setsliderTitle("");
        fileInputRef2.current.value = ""; // Clear file input
        setSliderErrors({ title: "", img: "" });
      } else {
        console.error("Failed to save data");
        message.error("Failed to add slider");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchslider = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/homeslider/fetch-home-slider"
      );
      setSliderImage(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  const sliderdelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/homeslider/delete-home-slider/${id}`
      );
      if (response.status === 200) {
        console.log("Data deleted successfully!");
        message.success("Slider deleted successfully");
        fetchslider(); // Fetch updated data after deletion
      } else {
        console.error("Failed to delete data");
        message.error("Failed to delete slider");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // video section connection
  const [videotext, setVideoText] = useState("");
  const [videoerror, setVideoError] = useState({ title: "" });
  const [videos, setFetchVideos] = useState([]);

  const HomeVideoUpload = async (a) => {
    a.preventDefault();

    if (!videotext.trim()) {
      setVideoError({
        title: "Text is required",
      });
      return;
    }
    try {
      const response = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/homevideos/add-home-videos",
        {
          video: videotext,
        }
      );
      if (response.status === 201) {
        console.log("Data saved successfully!");
        message.success("Home video added successfully");
        setVideoText("");
        setVideoError({ title: "" });
        fetchHomeVideos();
      } else {
        console.error("Failed to save data");
        message.error("Failed to add home video");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchHomeVideos = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/homevideos/fetch-home-videos"
      );
      setFetchVideos(response.data); // Update images state with fetched data
    } catch (error) {
      console.error("Error fetching home videos:", error);
    }
  };

  const HomeVideosDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/homevideos/delete-home-videos/${id}`
      );
      console.log(response); // Log the response for debugging
      if (response.status === 200) {
        console.log("home video deleted successfully!");
        message.success("Home video deleted successfully");
        fetchHomeVideos();
      } else {
        console.error("Failed to delete home video");
        message.error("Failed to delete home video");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchHomeVideos();
  }, []);

  const handlevideoChange = (a) => {
    setVideoText(a.target.value);
  };

  // podcast section connection
  const [podcasttitle, setpodcastTitle] = useState("");
  const [podcastaudio, setPodcastAudio] = useState([]);
  const [podcasterrors, setPodcastErrors] = useState({ title: "", audio: "" });

  useEffect(() => {
    fetchpodcast(); // Fetch data when component mounts
  }, []); // Empty dependency array to only run once

  const podcastTitleChange = (e) => {
    setpodcastTitle(e.target.value);
  };

  const podcastupload = async (e) => {
    e.preventDefault();

    if (!podcasttitle.trim() || !fileInputRef3.current.files[0]) {
      setPodcastErrors({
        title: !podcasttitle.trim() ? "Title is required" : "",
        audio: !fileInputRef3.current.files[0] ? "sliderimage is required" : "",
      });
      return;
    }

    const podcastformData = new FormData();
    podcastformData.append("title", podcasttitle);
    podcastformData.append("audio", fileInputRef3.current.files[0]);

    try {
      const response = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/homepodcast/add-home-podcast",
        podcastformData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        console.log("Data saved successfully!");
        fetchpodcast();
        message.success("Podcast added successfully");
        setpodcastTitle("");
        fileInputRef3.current.value = ""; // Clear file input
        setPodcastErrors({ title: "", audio: "" });
      } else {
        console.error("Failed to save data");
        message.error("Failed to add podcast");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchpodcast = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/homepodcast/fetch-home-podcast"
      );
      setPodcastAudio(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  const podcastdelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/homepodcast/delete-home-podcast/${id}`
      );
      if (response.status === 200) {
        console.log("Data deleted successfully!");
        fetchpodcast(); // Fetch updated data after deletion
        message.success("Podcast deleted successfully");
      } else {
        console.error("Failed to delete data");
        message.error("Failed to delete podcast");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      <h1>Home page</h1>
      <br />
      <h2>My Company</h2>
      <form onSubmit={companyupload}>
        <div className="inputcard">
          <div className="formdata">
            <label>Company Description</label>
            <input
              type="text"
              id="title"
              value={companydescription}
              onChange={companydescriptionChange}
              placeholder="Enter Title"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {companyerrors.description}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Company Logo (200x200)</label>
            <input type="file" ref={fileInputRef1} />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {companyerrors.logo}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button>Submit</button>
          </div>
        </div>
      </form>
      <br></br>
      <br></br>
      <div>
        <h2>Company Data</h2>
        <table className="wholetable">
          <thead>
            <tr>
                <th className="tablelogo">Logo</th>
              <th className="tabledescription">Description</th>
              <th className="tablebutton">Button</th>
            </tr>
          </thead>
          <tbody>
            {companylogo.map((item, index) => (
              <tr key={index}>
                <td>
                  <img
                    src={item.logo}
                    alt={`Logo ${index + 1}`}
                    className="fetch-logo"
                  />
                </td>
                <td>{item.description}</td>
                <td>
                  <img
                    src={deleteicon}
                    onClick={() => companydelete(item._id)}
                    alt="delete-icon"
                    className="icons"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div className="dividerLine"></div>
      <br />
      <br />
      <h2>Slider Section</h2>
      <form onSubmit={sliderupload}>
        <div className="inputcard">
          <div className="formdata">
            <label>Slider Title</label>
            <input
              type="text"
              id="title"
              value={slidertitle}
              onChange={sliderTitleChange}
              placeholder="Enter Title"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {slidererrors.title}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Slider Image</label>
            <input type="file" ref={fileInputRef2} />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {slidererrors.img}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button>Submit</button>
          </div>
        </div>
      </form>
      <br></br>
      <br></br>
      <div>
        <h2>Slider Data</h2>
        <table className="wholetable">
          <thead>
            <tr>
              <th className="tabletitle">Title</th>
              <th className="tableimg">Slider Image</th>
              <th className="tablebutton">Button</th>
            </tr>
          </thead>
          <tbody>
            {sliderimage.map((item, index) => (
              <tr key={index}>
                <td>{item.title}</td>
                <td>
                  <img
                    src={item.image}
                    alt={`Image ${index + 1}`}
                    className="fetch-img"
                  />
                </td>
                <td>
                  <img
                    src={deleteicon}
                    onClick={() => sliderdelete(item._id)}
                    alt="delete-icon"
                    className="icons"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div className="dividerLine"></div>
      <br />
      <br />
      <h2>Video Section</h2>
      <form onSubmit={HomeVideoUpload}>
        <div className="inputcard">
          <div className="formdata">
            <label>Home Page Video Iframe</label>
            <input
              type="text"
              id="videotext"
              value={videotext}
              onChange={handlevideoChange}
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {videoerror.title}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button type="submit">Submit</button>
          </div>
        </div>
      </form>
      <h3>Home Page Videos</h3>
      <table className="wholetable">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Video</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {videos.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <iframe
                  width="300"
                  height="160"
                  src={`https://www.youtube.com/embed/${item.video}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </td>
              <td>
                <img
                  src={deleteicon}
                  onClick={() => HomeVideosDelete(item._id)}
                  alt="delete-icon"
                  className="icons"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
      <div className="dividerLine"></div>
      <br />
      <br />
      <h2>Podcast Section</h2>
      <form onSubmit={podcastupload}>
        <div className="inputcard">
          <div className="formdata">
            <label>Podcast Title</label>
            <input
              type="text"
              id="title"
              value={podcasttitle}
              onChange={podcastTitleChange}
              placeholder="Enter Title"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {podcasterrors.title}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Podcast Audio</label>
            <input type="file" ref={fileInputRef3} />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {podcasterrors.audio}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button>Submit</button>
          </div>
        </div>
      </form>
      <br></br>
      <br></br>
      <div>
        <h2>Home Podcast Data</h2>
        <table className="wholetable">
          <thead>
            <tr>
              <th className="tabletitle">Title</th>
              <th>Audio</th>
              <th className="tablebutton">Button</th>
            </tr>
          </thead>
          <tbody>
            {podcastaudio.map((item, index) => (
              <tr key={index}>
                <td>{item.title}</td>
                <td>{item.audio}</td>
                <td>
                  <img
                    src={deleteicon}
                    onClick={() => podcastdelete(item._id)}
                    alt="delete-icon"
                    className="icons"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Home;
