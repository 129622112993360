import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import deleteicon from "../Assests/delete.png";
import editicon from "../Assests/edit.png";
import Layout from "../components/Layout";
import { message, Modal } from "antd";
import { Editor } from "@tinymce/tinymce-react";

const Consultingdetailed = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [modalContent, setModalContent] = useState(""); // New state for content inside modal
  const [errors, setErrors] = useState({ title: "", content: "" });
  const [consultingData, setConsultingData] = useState([]);
  const [editData, setEditData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const editorRef = useRef(null);
  const modalEditorRef = useRef(null);

  const handleEdit = (data) => {
    setEditData(data);
    setModalContent(data.content); // Initialize modalContent with current content
    setIsModalVisible(true);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setErrors((prevState) => ({
      ...prevState,
      title: "",
    }));
  };

  const handleTitleChange2 = (e) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      title: e.target.value,
    }));
  };


  // const handleContentChange = (content, editor) => {
  //   if (isModalVisible) {
  //     setModalContent(content); // Update modalContent when there is a change in the modal editor
  //     setEditData({ ...editData, content }); // Update editData content if modal is open
  //   } else {
  //     setContent(content); // Update content outside modal if modal is not open
  //   }
  // };
  

  const handleContentChange = (content, editor) => {
    if (isModalVisible) {
      setModalContent(content); // Update modalContent when there is a change in the modal editor
      setEditData({ ...editData, content }); // Update editData content if modal is open
    } else {
      setContent(content); // Update content outside modal if modal is not open
    }
  
    // Apply dimension styles to images in both editors
    applyDimensionStyles();
  };
  
  const applyDimensionStyles = () => {
    const editors = [editorRef.current, modalEditorRef.current]; // Assuming modalEditorRef is a ref for the editor inside the modal
  
    editors.forEach(editor => {
      if (editor) {
        const doc = editor.editorContainer.querySelector('iframe').contentWindow.document;
        const images = doc.getElementsByTagName("img");
  
        for (let img of images) {
          img.style.display = "block";
          img.style.margin = "auto";
        }
      }
    });
  };
  
  


  const handleOk = async () => {
    // Update the consulting data
    try {
      await updateConsultingDetailedData(editData._id, { ...editData, content: modalContent }); // Update content with modalContent
      setIsModalVisible(false);
      message.success("Consulting data updated successfully");
      fetchConsultingData();
    } catch (error) {
      console.error("Error occurred:", error);
      message.error("Failed to update consulting data");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const updateConsultingDetailedData = async (id, newData) => {
    try {
      await axios.put(
        `https://api-prashant.we-matter.xyz/api/v1/consulting-detailed/update-consult-detailed/${id}`,
        newData
      );
    } catch (error) {
      console.error("Error occurred:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate title and content
    if (!title?.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        title: "Title should not be empty",
      }));
      return;
    }
    if (!content?.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        content: "Content should not be empty",
      }));
      return;
    }

    try {
      // Send form data to the server
      const response = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/consulting-detailed/add-consult-detailed",
        { title, content }
      );

      if (response.status === 200) {
        setTitle("");
        setContent("");
        setErrors({ title: "", content: "" }); // Clear error messages after successful submission
        fetchConsultingData();
        message.success("Consulting detailed data added successfully");
      } else {
        console.error("Failed to add consulting detailed data");
        // Handle failure, e.g., show error message
      }
    } catch (error) {
      console.error("Error occurred:", error);
      message.error("Failed to consulting detailed data");

      // Handle error, e.g., show error message
    }
  };

  const fetchConsultingData = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/consulting-detailed/fetch-consult-detailed"
      );
      setConsultingData(response.data);
    } catch (error) {
      console.error("Error fetching consulting detailed data:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/consulting-detailed/delete-consult-detailed/${id}`
      );
      if (response.status === 200) {
        console.log("Data deleted successfully!");
        fetchConsultingData(); // Fetch updated data after deletion
        message.success("Consulting data deleted successfully");
      } else {
        console.error("Failed to delete data");
        message.error("Failed to delete consulting detailed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchConsultingData(); // Fetch consulting data when component mounts
  }, []);

  return (
    <Layout>
      <h1>Consulting Detailed Page</h1>
      <form onSubmit={handleSubmit}>
        <div className="inputcard2">
          <div className="formdata">
            <label>Consulting Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter Title"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.title}
              </span>
            </div>
          </div>
          <div className="formdata2">
            <label>Consulting Content</label>
            <Editor
              apiKey="jcx0paz16mauzj0cb6b8f4pngamne4w8elqs7moz9s0745l4"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue=""
              value={isModalVisible ? modalContent : content} // Use modalContent if modal is visible
              onEditorChange={handleContentChange}
              init={{
                height: 500,
                statusbar: false,
                menubar: true,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                  "file",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | removeformat | help",
                content_style:
                  "body { font-family:poppins ,Arial,sans-serif; font-size:1rem }",
                file_picker_types: "file image media",
              }}
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.content}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button>Submit</button>
          </div>
        </div>
      </form>
      <br />
      <br />
      <br />
      <div>
        <h2>Consulting Detailed Data</h2>
        <table className="wholetable">
          <thead>
            <tr>
              <th className="contenttabletitle">Title</th>
              <th className="contenttablecontent">Content</th>
              <th className="contenttablebutton">Button</th>
            </tr>
          </thead>
          <tbody>
            {consultingData.map((item, index) => (
              <tr key={index}>
                <td className="contenttabletitle">{item.title}</td>
                <td
                  className="contenttablecontent"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
                <td className="contenttablebutton">
                  <img
                    src={deleteicon}
                    onClick={() => handleDelete(item._id)}
                    alt="delete-icon"
                    className="icons"
                  />
                  <br />
                  <br />
                  <br />
                  <img
                    src={editicon}
                    alt="delete-icon"
                    className="icons"
                    onClick={() => handleEdit(item)}
                  />
                  <Modal
                    title="Edit Blog"
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    style={{
                      Height: "fitcontent",
                      position: "absolute",
                      top: "10%",
                      left: "30%",
                      bottom: "30%",
                      width: "100%",
                    }}
                  >
                    <form className="edit-form">
                      <div className="edit-inputcard">
                        <div className="edit-formdata">
                          <label>Blog Title</label>
                          <input
                            type="text"
                            value={editData.title}
                            onChange={handleTitleChange2}
                            placeholder="Enter Title"
                          />
                        </div>
                        <Editor
                          apiKey="jcx0paz16mauzj0cb6b8f4pngamne4w8elqs7moz9s0745l4"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          initialValue={item.content}
                          value={modalContent} // Use modalEditorContent if modal is visible
                          onEditorChange={handleContentChange} // Use handlupdatedata only if modal is visible
                          init={{
                            height: 500,
                            statusbar: false,
                            menubar: true,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                              "file",
                            ],
                            toolbar:
                              "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | removeformat | help",
                            content_style:
                              "body { font-family:poppins ,sans-serif; font-size:1rem }",
                            file_picker_types: "file image media",
                          }}
                        />
                      </div>
                    </form>
                  </Modal>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Consultingdetailed;
