import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import deleteicon from "../Assests/delete.png";
import editicon from "../Assests/edit.png";
import Layout from "../components/Layout";
import { message, Modal } from "antd";

const Consulting = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(""); // Added description state
  const [consultingData, setConsultingData] = useState([]);
  const [errors, setErrors] = useState({ title: "", description: "", img: "" }); // Updated errors state
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const [editData, setEditData] = useState({}); // State to store data for editing
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchConsultingData();
  }, []);

  const handleEdit = (data) => {
    console.log("Editing data:", data); // Check the content of the data object received
    setEditData(data);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    // Update the consulting data
    try {
      await updateConsultingData(editData._id, editData);
      setIsModalVisible(false);
      message.success("Consulting data updated successfully");
      fetchConsultingData();
    } catch (error) {
      console.error("Error occurred:", error);
      message.error("Failed to update consulting data");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const updateConsultingData = async (id, newData) => {
    try {
      const response = await axios.put(
        `https://api-prashant.we-matter.xyz/api/v1/consulting/update-consult/${id}`,
        newData
      );
    } catch (error) {
      console.error("Error occurred:", error);
      throw error;
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    // Function to handle description input change
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !title.trim() ||
      !description.trim() ||
      !fileInputRef.current.files[0]
    ) {
      setErrors({
        title: !title.trim() ? "Title is required" : "",
        description: !description.trim() ? "Description is required" : "",
        img: !fileInputRef.current.files[0] ? "Image is required" : "",
      });
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description); // Added description to form data
    formData.append("image", fileInputRef.current.files[0]);

    try {
      const response = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/consulting/add-consult",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        console.log("Data saved successfully!");
        message.success("Consultiing data added successfully");
        fetchConsultingData();
        setTitle("");
        setDescription(""); // Clear description input
        fileInputRef.current.value = "";
        setErrors({ title: "", description: "", img: "" });
      } else {
        console.error("Failed to save data");
        message.error("Failed to add data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchConsultingData = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/consulting/fetch-consult"
      );
      setConsultingData(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/consulting/delete-consult/${id}`
      );
      if (response.status === 200) {
        console.log("Data deleted successfully!");
        message.success("Consulting data deleted");
        fetchConsultingData();
      } else {
        console.error("Failed to delete data");
        message.error("Failed to delete consulting data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      <div>
        <h1>Consulting Page</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="inputcard">
          <div className="formdata">
            <label>Consulting Card Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter Title"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.title}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Description</label>
            <input
              type="text"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Enter Description"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.description}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Consulting Card Image</label>
            <input type="file" ref={fileInputRef} />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.img}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button>Submit</button>
          </div>
        </div>
      </form>
      <br></br>
      <br></br>
      <div>
        <h2>Consulting Data</h2>
        <table className="wholetable">
          <thead>
            <tr>
              <th>Title</th>
              <th>Image</th>
              <th>Description</th>
              <th>Button</th>
            </tr>
          </thead>
          <tbody>
            {consultingData.map((item, index) => (
              <tr key={index}>
                <td>{item.title}</td>
                <td>
                  <img
                    src={item.image}
                    alt={`${item.title} Image`}
                    className="fetch-img"
                  />
                </td>
                <td>{item.description}</td>
                <td>
                  <img
                    src={deleteicon}
                    onClick={() => handleDelete(item._id)}
                    alt="delete-icon"
                    className="icons"
                  />
                  <br />
                  <img
                    src={editicon}
                    alt="delete-icon"
                    className="icons"
                    onClick={() => handleEdit(item)}
                  />
                  {/* Modal for editing */}
                  <Modal
                    title="Edit Consulting Data"
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    style={{
                      maxHeight: "800px",
                      overflow: "auto",
                      position: "absolute",
                      top: "10%",
                      left: "30%",
                      bottom: "0%",
                    }}
                  >
                    <form className="edit-form">
                      <div className="edit-inputcard">
                        <div className="edit-formdata">
                          <label>Consulting Card Title</label>
                          <input
                            type="text"
                            value={editData.title}
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                title: e.target.value,
                              })
                            }
                            placeholder="Enter Title"
                          />
                        </div>
                        <div className="edit-formdata">
                          <label>Description</label>
                          <input
                            type="text"
                            value={editData.description}
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                description: e.target.value,
                              })
                            }
                            placeholder="Enter Description"
                          />
                        </div>
                        <div className="edit-formdata">
                          <label>Consulting Card Image</label>
                          <input 
  type="file" 
  ref={fileInputRef2} 
  onChange={(e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the file size exceeds the limit (1MB = 1048576 bytes)
      if (file.size <= 1048576) {
        // File is within the allowed size limit
        // Read the file and convert it into a data URL
        const reader = new FileReader();
        reader.onload = () => {
          // Set the data URL as the image URL in the editData state
          setEditData({
            ...editData,
            image: reader.result, // Store the data URL
            imageData: file, // Store the file object for later use (if needed)
          });
        };
        reader.readAsDataURL(file);
      } else {
        // File exceeds the size limit
        // Reset the file input and show an error message
        e.target.value = ""; // Reset the file input
        console.error("File size exceeds the limit (1MB)");
        alert("File size exceeds the limit (1MB)");
      }
    }
  }} 
/>


                        </div>
                        <img
                          src={editData.image}
                          alt="Consulting Image"
                          className="edit-fetch-img"
                        />
                      </div>
                    </form>
                  </Modal>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Consulting;
