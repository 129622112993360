import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import deleteicon from "../Assests/delete.png";
import Layout from "../components/Layout";
import { message } from "antd";

const Album = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [albumTitle, setAlbumTitle] = useState("");
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [error, setError] = useState({ title: "", imgs: "" });
  const [existingerror, setExistingError] = useState({
    selecttitle: "",
    selectimages: "",
  });
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const [albumData, setAlbumData] = useState([]);

  useEffect(() => {
    fetchAlbum();
  }, []);

  useEffect(() => {
    console.log("selectedAlbum after re-render:", selectedAlbum);
  }, [selectedAlbum]); // Log whenever selectedAlbum changes

  const fetchAlbum = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/album/fetch-album"
      );
      setAlbumData(response.data || []);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    setSelectedFiles(fileList);
  };

  const handleFileChange2 = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    setSelectedFiles(fileList);
  };

  const handleAlbumChange = (event) => {
    setSelectedAlbum(event.target.value);
  };

  const addAlbum = async (e) => {
    e.preventDefault();

    if (
      !albumTitle.trim() ||
      !fileInputRef.current ||
      !fileInputRef.current.files ||
      !fileInputRef.current.files.length
    ) {
      setError({
        title: !albumTitle.trim() ? "Title is required" : "",
        imgs:
          !fileInputRef.current ||
          !fileInputRef.current.files ||
          !fileInputRef.current.files.length
            ? "At least one image is required"
            : "",
      });
      return;
    }

    const formData = new FormData();
    formData.append("title", albumTitle);

    for (let i = 0; i < fileInputRef.current.files.length; i++) {
      formData.append("images", fileInputRef.current.files[i]);
    }

    try {
      const response = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/album/add-album",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        console.log("album saved successfully!");
        message.success("Album added");
        setAlbumTitle("");
        fileInputRef.current.value = "";
        setError({ title: "", imgs: "" });
        fetchAlbum();
      } else {
        console.error("Failed to save album");
        message.error("Failed to add album!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const addImagesToAlbum = async (a) => {
    a.preventDefault();
    
    if (!selectedAlbum || !fileInputRef2.current.files.length) {
      setExistingError({
        selecttitle: !selectedAlbum.trim() ? "Title is required" : "",
        selectimages: !fileInputRef2.current.files.length
          ? "At least one image is required"
          : "",
      });
      return;
    }
  
    const formData = new FormData();
  
    // Append files to FormData using the correct field name ("addnew" in this case)
    for (let i = 0; i < fileInputRef2.current.files.length; i++) {
      formData.append("addnew", fileInputRef2.current.files[i]);
    }
  
    try {
      const response = await axios.post(
        `https://api-prashant.we-matter.xyz/api/v1/album/add-images-to-album/${selectedAlbum}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 200) {
        console.log("Images added to album successfully!");
        message.success("Images added to album!");
        setSelectedAlbum(""); 
        fileInputRef2.current.value = "";
        setError({ imgs: "" });
        fetchAlbum();
      } else {
        console.error("Failed to add images to album");
        message.error("Failed to add images to album!"); // Display error message using Ant Design
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const deleteAlbumHandler = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/album/delete-album/${id}`
      );
      if (response.status === 200) {
        console.log("Album deleted successfully!");
        message.success("Album deleted!");
        fetchAlbum(); // Fetch updated data after deletion
      } else {
        console.error("Failed to delete album");
        message.error("Failed to delete album!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const deleteImageHandler = async (albumId, imageIndex) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/album/delete-image/${albumId}/${imageIndex}`
      );
      if (response.status === 200) {
        console.log("Image deleted successfully from album!");
        message.success("Image deleted from album!");
        fetchAlbum(); // Fetch updated album data after deletion
      } else {
        console.error("Failed to delete image from album");
        message.error("Failed to delete image from album!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      <h1>Album Page</h1>

      {/* Form to add a new album */}
      <div className="albumforms">
      <div className="forms" style={{borderRight: '2px solid black'}}>
      <h2>Add New Album</h2>
      <form onSubmit={addAlbum}>
        <div className="inputcard2">
          <div className="formdata">
            <label>Album Title</label>
            <input
              type="text"
              id="title"
              value={albumTitle}
              onChange={(e) => setAlbumTitle(e.target.value)}
              placeholder="Enter Title"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {error.title}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Album Image (Max 20)</label>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
            />

            <div className="part2">
              <span className="errorclass" id="errorname">
                {error.imgs}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button>Submit</button>
          </div>
        </div>
      </form>
      </div>
      <div className="forms" style={{borderLeft: '2px solid black'}}>
      <h2>Update to the Album</h2>
      <form onSubmit={addImagesToAlbum}>
        <div className="inputcard2">
          <div className="formdata">
            <label>Select Album</label>
            <select onChange={handleAlbumChange}>
              <option value="">Select Album</option>
              {albumData.map((album) => (
                <option key={album._id} value={album._id}>
                  {album.title}
                </option>
              ))}
            </select>
            <div className="part2">
              <span className="errorclass" id="errorname">
                {existingerror.selecttitle}
              </span>
            </div>
          </div>
          <div className="formdata">
          <label>Upload Images</label>
            <input
              type="file"
              multiple
              name="addnew"
              onChange={handleFileChange2}
              ref={fileInputRef2}
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {existingerror.selectimages}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button>Add Images to Album</button>
          </div>
        </div>
      </form>
      </div>
      </div>
      <br />
      <br />
      <br />
      <div className="grid-container">
        {albumData.map((item, index) => (
          <div key={index} className="grid-item">
            <div className="album-heading">
              <h2 style={{ display: "inline" }}>{item.title}</h2>
              <img
                src={deleteicon}
                alt="delete-icon"
                onClick={() => deleteAlbumHandler(item._id)}
                className="albumicons"
              />
            </div>
            <div className="file-display">
              {item.images &&
                item.images.map((file, fileIndex) => (
                  <div className="outerimg">
                  <div className="image-container">
                  <img
                    key={fileIndex}
                    src={file}
                    alt={`File ${fileIndex}`}
                    className="album-images"
                  />
                    <div className="overlay">
                      <img src={deleteicon} onClick={() => deleteImageHandler(item._id, fileIndex)} className="overlay-image"></img>
                    </div>
                  </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </Layout>

  );
};

export default Album;
