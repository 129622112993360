import React from "react";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home.js";
import Media from "./pages/Media.js";
import About from "./pages/About.js";
import Blogs from "./pages/Blogs.js";
import Consulting from "./pages/Consulting.js";
import Consultingdetailed from "./pages/Consultingdetailed.js";
import Users from "./pages/Users.js";
import Login from "./pages/Login.js";
import Album from "./pages/Album.js";
import Contact from "./pages/Contact.js";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <ProtectedRoutes>
              <Home />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/about"
          element={
            <ProtectedRoutes>
              <About />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/consulting"
          element={
            <ProtectedRoutes>
              <Consulting />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/consultingdetailed"
          element={
            <ProtectedRoutes>
              <Consultingdetailed />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/media"
          element={
            <ProtectedRoutes>
              <Media />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/album"
          element={
            <ProtectedRoutes>
              <Album />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/blog"
          element={
            <ProtectedRoutes>
              <Blogs />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoutes>
              <Users />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/messages"
          element={
            <ProtectedRoutes>
              <Contact />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </>
  );
};

export function ProtectedRoutes(props) {
  if (localStorage.getItem("user")) {
    return props.children;
  } else {
    return <Navigate to="/" />;
  }
}

export default App;
