import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import Layout from "../components/Layout";
import deleteicon from "../Assests/delete.png";

const Contact = () => {
  const [contactData, setContactData] = useState([]);
  const [filterOption, setFilterOption] = useState("");

  useEffect(() => {
    fetchContact();
  }, []);

  const fetchContact = async () => {
    try {
      const response = await axios.get("https://api-prashant.we-matter.xyz/api/v1/contact/fetch-contact");
      if (response.status === 200) {
        setContactData(response.data.reverse());
      } else {
        console.error("Failed to fetch contact data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };

  const deleteContact = async (id) => {
    try {
      const response = await axios.delete(`https://api-prashant.we-matter.xyz/api/v1/contact/delete-contact/${id}`);
      if (response.status === 200) {
        console.log("Data deleted successfully!");
        message.success("Contact data deleted successfully!");
        fetchContact(); // Fetch updated data after deletion
      } else {
        message.error("Failed to delete data");
        console.error("Failed to delete contact data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangeStatus = async (id, status) => {
    try {
      const response = await axios.put(`https://api-prashant.we-matter.xyz/api/v1/contact/update-status/${id}`, {
        status: status,
      });
      if (response.status === 200) {
        console.log("Status updated successfully!");
        message.success("Status updated successfully!");
        fetchContact(); // Fetch updated data after status update
      } else {
        message.error("Failed to update status");
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };

  // Filter the contact data based on the selected filter option
  const filteredContacts = filterOption
    ? contactData.filter((item) => item.status === filterOption)
    : contactData;

  return (
    <Layout>
      <h1>Contacts page</h1>
      <div className="status-filter">
        <label>Status Filter: &nbsp; </label>
        <select value={filterOption} onChange={handleFilterChange}>
          <option value="">All</option>
          <option value="Pending">Pending</option>
          <option value="Recognised">Recognised</option>
          <option value="Decline">Decline</option>
        </select>
      </div>
      {filteredContacts.map((item, index) => (
        <div key={index} className={`contact-card ${item.status}`}>
          <div className="contact-details">
            <p>
              <label>Name: &nbsp; </label>
              {item.name}
            </p>
            <p>
              <label>Email: &nbsp; </label>
              {item.email}
            </p>
            <p>
              <label>Contact: &nbsp; </label>
              {item.contact}
            </p>
            <p>
              <label>Company Name: &nbsp; </label>
              {item.companyname}
            </p>
            <p>
              <label>Message: &nbsp; </label>
              {item.message}
            </p>
          </div>
          <div className="contact-button">
            <div className="statuscontainer">
              <label>Status: </label>
              <select
                value={item.status}
                onChange={(e) => handleChangeStatus(item._id, e.target.value)}
              >
                <option value="Pending">Pending</option>
                <option value="Recognised">Recognised</option>
                <option value="Decline">Decline</option>
              </select>
            </div>
            <br />
            <br />
            <img
              src={deleteicon}
              onClick={() => deleteContact(item._id)}
              alt="delete-icon"
              className="icons"
            />
          </div>
        </div>
      ))}
    </Layout>
  );
};

export default Contact;
