import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import Layout from "../components/Layout";
import deleteicon from "../Assests/delete.png";

const Users = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, SetErrors] = useState({ name: "", email: "", password: "" });
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const addUserData = async (e) => {
    e.preventDefault();

    if (!name.trim() || !email.trim() || !password.trim()) {
      SetErrors({
        name: !name.trim() ? "Name is required" : "",
        email: !email.trim() ? "Email is required" : "",
        password: !password.trim() ? "Password is required" : "",
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/users/add-user",
        {
          name: name,
          email: email,
          password: password,
        }
      );
      if (response.status === 201) {
        console.log("Data saved successfully!");
        fetchUserData();
        message.success("New user added!");
        setName("");
        setEmail("");
        setPassword("");
        SetErrors({ name: "", email: "", password: "" });
      } else {
        console.error("Failed to save data");
        message.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to save data");
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/users/fetch-user"
      );
      if (response.status === 200) {
        setUserData(response.data.reverse());
      } else {
        console.error("Failed to fetch user data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const deleteUserData = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/users/delete-user/${id}`
      );
      if (response.status === 200) {
        console.log("Data deleted successfully!");
        message.success("User data deleted successfully!");
        fetchUserData(); // Fetch updated data after deletion
      } else {
        message.error("Failed to delete data");
        console.error("Failed to delete User data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      <h1>Users page</h1>
      <br />
      <form onSubmit={addUserData}>
        <div className="inputcard">
          <div className="formdata">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Enter Name"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.name}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Email</label>
            <input
              type="text"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter Email"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.email}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Password</label>
            <input
              type="text"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter Password"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.password}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button type="submit">Submit</button>
          </div>
        </div>
      </form>
      <br></br>
      <br></br>
      <div>
        <h2>Consulting Data</h2>
        <table className="wholetable">
          <thead>
            <tr>
              <th>Sr. no</th>
              <th>Name</th>
              <th>Email</th>
              <th>Password</th>
              <th>Button</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <th>{item.password}</th>
                <td>
                  <img
                    src={deleteicon}
                    onClick={() => deleteUserData(item._id)}
                    alt="delete-icon"
                    className="icons"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Users;
