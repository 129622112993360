import React, { Children } from "react";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  return (
    <div>
      <Sidebar>
        <div className="content"> {children}</div>
      </Sidebar>
    </div>
  );
};

export default Layout;
