import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import deleteicon from "../Assests/delete.png";
import editicon from "../Assests/edit.png";
import Layout from "../components/Layout";
import { message, Modal } from "antd";
import { Editor } from "@tinymce/tinymce-react";

const Blogs = () => {
  const [cover, setCover] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState({ title: "", cover: "", content: "" });
  const [blogData, setBlogData] = useState([]);
  const fileInputRef = useRef(null);
  const [editData, setEditData] = useState({}); // State to store data for editing
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(""); // New state for content inside modal

  const editorRef = useRef(null);
  const modalEditorRef = useRef(null);

  const handleEdit = (data) => {
    setEditData(data);
    setModalContent(data.content); // Initialize modalContent with current content
    setIsModalVisible(true);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setErrors((prevState) => ({
      ...prevState,
      title: "",
    }));
  };

  const handleTitleChange2 = (e) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      title: e.target.value,
    }));
  };

  const handleContentChange = (content, editor) => {
    if (isModalVisible) {
      setModalContent(content); // Update modalContent when there is a change in the modal editor
      setEditData({ ...editData, content }); // Update editData content if modal is open
    } else {
      setContent(content); // Update content outside modal if modal is not open
    }

    // Apply dimension styles to images in both editors
    applyDimensionStyles();
  };

  const applyDimensionStyles = () => {
    const editors = [editorRef.current, modalEditorRef.current]; // Assuming modalEditorRef is a ref for the editor inside the modal

    editors.forEach((editor) => {
      if (editor) {
        const doc =
          editor.editorContainer.querySelector("iframe").contentWindow.document;
        const images = doc.getElementsByTagName("img");

        for (let img of images) {
          img.style.display = "block";
          img.style.margin = "auto";
        }
      }
    });
  };

  const handleOk = async () => {
    // Update the consulting data
    try {
      await updateBlogData(editData._id, {
        ...editData,
        content: modalContent,
      }); // Update content with modalContent
      setIsModalVisible(false);
      message.success("Consulting data updated successfully");
      fetchBlogData();
    } catch (error) {
      console.error("Error occurred:", error);
      message.error("Failed to update consulting data");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const updateBlogData = async (id, newData) => {
    try {
      await axios.put(
        `https://api-prashant.we-matter.xyz/api/v1/blog/update-blog/${id}`,
        newData
      );
    } catch (error) {
      console.error("Error occurred:", error);
      throw error;
    }
  };

  const addblog = async (e) => {
    e.preventDefault();
    console.log("Adding blog...");

    // Ensure fileInputRef.current is not null before accessing files property
    console.log("fileInputRef.current:", fileInputRef.current);
    console.log(
      "fileInputRef.current.files[0]:",
      fileInputRef.current?.files[0]
    );

    if (!fileInputRef.current || !fileInputRef.current.files[0]) {
      console.log("Cover image is required");
      setErrors((prevState) => ({
        ...prevState,
        cover: "Cover image is required",
      }));
      return;
    }

    // Validate title and content
    if (!title?.trim()) {
      console.log("Title should not be empty");
      setErrors((prevState) => ({
        ...prevState,
        title: "Title should not be empty",
      }));
      return;
    }
    if (!content?.trim()) {
      console.log("Content should not be empty");
      setErrors((prevState) => ({
        ...prevState,
        content: "Content should not be empty",
      }));
      return;
    }

    try {
      console.log("Sending request to add blog...");
      const formData = new FormData();
      formData.append("title", title);
      formData.append("cover", cover); // Ensure cover image is appended
      formData.append("content", content);

      const response = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/blog/add-blog",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Response:", response); // Log the response from the API
      if (response.status === 200) {
        setTitle("");
        setContent("");
        console.log("Form data:", { title, cover, content });
        fileInputRef.current.value = "";
        setCover(null); // Reset the cover state after successful submission
        setErrors({ title: "", cover: "", content: "" }); // Clear error messages after successful submission
        fetchBlogData();
        message.success("Blog data added successfully");
      } else {
        console.error("Failed to add blog data");
        console.log("Form data:", { title, cover, content });
      }
    } catch (error) {
      console.error("Error occurred:", error);
      message.error("Failed to add blog");
      console.log("Form data:", { title, cover, content });
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  const fetchBlogData = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/blog/fetch-blog"
      );
      setBlogData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  const deleteBlogData = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/blog/delete-blog/${id}`
      );
      if (response.status === 200) {
        console.log("Data deleted successfully!");
        fetchBlogData(); // Fetch updated data after deletion
        message.success("Blog deleted succesfully");
      } else {
        console.error("Failed to delete data");
        message.error("Failed to delete blog");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      <h1>Blog Page</h1>
      <form onSubmit={addblog}>
        <div className="inputcard2">
          <div className="formdata">
            <label>Blog Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter Title"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.title}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Blog cover</label>
            <input
              type="file"
              name="cover"
              onChange={(e) => setCover(e.target.files[0])}
              ref={fileInputRef} // Ensure that the ref is assigned here
            />

            <div className="part2">
              <span className="errorclass" id="errorname">
                {setErrors.cover}
              </span>
            </div>
          </div>
          <div className="formdata2">
            <label>Blog Content</label>
            <Editor
              apiKey="jcx0paz16mauzj0cb6b8f4pngamne4w8elqs7moz9s0745l4"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue=""
              value={isModalVisible ? modalContent : content} // Use modalContent if modal is visible
              onEditorChange={handleContentChange}
              init={{
                height: 500,
                statusbar: false,
                menubar: true,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                  "file",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | removeformat | help",
                content_style:
                  "body { font-family:poppins ,Arial,sans-serif; font-size:1rem }",
                file_picker_types: "file image media",
              }}
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {errors.content}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button>Submit</button>
          </div>
        </div>
      </form>
      <br />
      <br />
      <br />
      <div>
        <h2>Blog Detailed Data</h2>
        <table className="wholetable">
          <thead>
            <tr>
              <th className="contenttabletitle">Blog Title</th>
              <th className="covertablecontent">Blog Cover</th>
              <th className="contenttablecontent">Blog Content</th>
              <th className="contenttablebutton">Button</th>
            </tr>
          </thead>
          <tbody>
            {blogData.map((item, index) => (
              <tr key={index}>
                <td className="contenttabletitle">{item.title}</td>
                <td>
                  <img
                    src={item.cover}
                    alt={`Image ${index + 1}`}
                    className="cover-img"
                  />
                </td>
                <td
                  className="contenttablecontent"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
                <td className="contenttablebutton">
                  <img
                    src={deleteicon}
                    onClick={() => deleteBlogData(item._id)}
                    alt="delete-icon"
                    className="icons"
                  />
                  <br />
                  <br />
                  <br />
                  <img
                    src={editicon}
                    alt="delete-icon"
                    className="icons"
                    onClick={() => handleEdit(item)}
                  />
                  <Modal
                    title="Edit Blog"
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    style={{
                      Height: "fitcontent",
                      position: "absolute",
                      top: "10%",
                      left: "30%",
                      bottom: "30%",
                    }}
                  >
                    <form className="edit-form">
                      <div className="edit-inputcard">
                        <div className="edit-formdata">
                          <label>Blog Title</label>
                          <input
                            type="text"
                            value={editData.title}
                            onChange={handleTitleChange2}
                            placeholder="Enter Title"
                          />
                        </div>
                        <div className="edit-formdata">
                          <label>Blog Cover</label>

                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                // Check if the file size exceeds the limit (1MB = 1048576 bytes)
                                if (file.size <= 1048576) {
                                  // File is within the allowed size limit
                                  // Read the file and convert it into a data URL
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    // Set the data URL as the image URL in the editData state
                                    setEditData({
                                      ...editData,
                                      cover: reader.result, // Store the data URL
                                      imageData: file, // Store the file object for later use (if needed)
                                    });
                                  };
                                  reader.readAsDataURL(file);
                                } else {
                                  // File exceeds the size limit
                                  // Reset the file input and show an error message
                                  e.target.value = ""; // Reset the file input
                                  console.error(
                                    "File size exceeds the limit (1MB)"
                                  );
                                  alert("File size exceeds the limit (1MB)");
                                }
                              }
                            }}
                          />
                        </div>
                        <img
                          src={editData.cover}
                          alt="Blog Image"
                          className="edit-fetch-img"
                        />
                        <br />
                        <label className="content-label">Blog Content</label>
                        <Editor
                          apiKey="jcx0paz16mauzj0cb6b8f4pngamne4w8elqs7moz9s0745l4"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          initialValue={item.content}
                          value={modalContent} // Use modalEditorContent if modal is visible
                          onEditorChange={handleContentChange} // Use handlupdatedata only if modal is visible
                          init={{
                            height: 500,
                            statusbar: false,
                            menubar: true,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                              "file",
                            ],
                            toolbar:
                              "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | removeformat | help",
                            content_style:
                              "body { font-family:poppins ,sans-serif; font-size:1rem }",
                            file_picker_types: "file image media",
                          }}
                        />
                      </div>
                    </form>
                  </Modal>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Blogs;
