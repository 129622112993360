import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import {
  faHome,
  faCircleInfo,
  faMoneyBill,
  faImage,
  faNewspaper,
  faBlog,
  faBars,
  faImages,
  faUser,
  faRightFromBracket,
  faInbox,
} from "@fortawesome/free-solid-svg-icons";
import { message } from "antd";

const Sidebar = ({ children }) => {
  const [isClose, setisClose] = useState(false);
  const toggle = () => setisClose(!isClose);
  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.removeItem("user");
    message.success("Logout Successfully");
    navigate("/login");
  };

  const menuItem = [
    {
      path: "/home",
      name: "Home",
      icon: <FontAwesomeIcon icon={faHome} />,
    },
    {
      path: "/about",
      name: "About",
      icon: <FontAwesomeIcon icon={faCircleInfo} />,
    },
    {
      path: "/consulting",
      name: "Consulting",
      icon: <FontAwesomeIcon icon={faMoneyBill} />,
    },
    {
      path: "/consultingdetailed",
      name: "Consulting Detailed",
      icon: <FontAwesomeIcon icon={faNewspaper} />,
    },
    {
      path: "/media",
      name: "Media",
      icon: <FontAwesomeIcon icon={faImage} />,
    },
    {
      path: "/blog",
      name: "Blogs",
      icon: <FontAwesomeIcon icon={faBlog} />,
    },
    {
      path: "/album",
      name: "Albums",
      icon: <FontAwesomeIcon icon={faImages} />,
    },
    {
      path: "/messages",
      name: "Messages",
      icon: <FontAwesomeIcon icon={faInbox} />,
    },
    {
      path: "/users",
      name: "Users",
      icon: <FontAwesomeIcon icon={faUser} />,
    },
  ];

  return (
    <div className="container">
      <div
        className="sidebar"
        style={{
          width: isClose ? "3rem" : "15rem",
          position: "fixed",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <div className="top_section">
          <h1 style={{ display: isClose ? "none" : "block" }} className="logo">
            Admin
          </h1>
          <div
            style={{
              marginLeft: isClose ? "0" : "6rem",
              marginTop: isClose ? "2rem" : "0",
            }}
            className="bars"
          >
            <FontAwesomeIcon icon={faBars} onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeClassName="active"
          >
            <div className="icon">{item.icon}</div>
            <div
              style={{ display: isClose ? "none" : "block" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}
        <br />
        <br />
        {isClose ? (
          <div className="sidebar-button">
            <FontAwesomeIcon
              className="logout-button"
              icon={faRightFromBracket}
              onClick={toggle}
            />
          </div>
        ) : (
          <div className="sidebar-button">
            <button className="logout-button" onClick={logoutHandler}>
              Logout
            </button>
          </div>
        )}
      </div>
      <main
        style={{
          marginLeft: isClose ? "3rem" : "15rem",
          transition: "margin-left 0.5s",
        }}
      >
        {children}
      </main>
    </div>
  );
};

export default Sidebar;
