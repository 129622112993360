import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import deleteicon from "../Assests/delete.png";
import Layout from "../components/Layout";
import { message } from "antd";

const Media = () => {
  const [file, setFile] = useState();
  const [position, setPosition] = useState(""); // Added position state
  const [description, setDescription] = useState(""); // Added description state
  const [images, setImages] = useState([]);
  const [videotext, setVideoText] = useState("");
  const [positionerrors, setPositionErrors] = useState({ img: "" });
  const [descriptionerrors, setDescriptionError] = useState({
    description: "",
  });
  const [videoerror, setVideoError] = useState({ title: "" });
  const [videos, setFetchVideos] = useState([]);
  const fileInputRef = useRef(null);

  const handlephotoUpload = async (e) => {
    e.preventDefault();

    // Check if file is selected
    if (!file) {
      setPositionErrors({ img: "Please select an image." });
      return;
    }

    if (!position) {
      setPositionErrors({
        ...positionerrors,
        position: "Position is required",
      });
      return;
    }

    if (!description) {
      setDescriptionError({
        ...descriptionerrors,
        description: "description is required",
      });
      return;
    }

    const formData = new FormData();
    formData.append("mediafile", file);
    formData.append("position", position); // Append position to form data
    formData.append("description", description); // Append description to form data

    try {
      const res = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/mediaphotos/add-media-photos",
        formData
      );
      console.log(res.data); // Log response data for debugging
      fetchUploadedImages();
      message.success("Photo added successfully");
      setPositionErrors({ img: "", position: "" }); // Clear errors
      setDescriptionError({ description: "" });
      setFile(null);
      setPosition(""); // Clear position input
      setDescription(""); // Clear description input
      fileInputRef.current.value = "";
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Failed to add photo");
    }
  };

  const fetchUploadedImages = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/mediaphotos/fetch-media-photos"
      );
      setImages(response.data); // Update images state with fetched data
    } catch (error) {
      console.error("Error fetching media photos:", error);
    }
  };

  const fetchUploadedVideos = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/mediavideos/fetch-media-videos"
      );
      setFetchVideos(response.data); // Update images state with fetched data
    } catch (error) {
      console.error("Error fetching media videos:", error);
    }
  };

  const mediaphotosDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/mediaphotos/delete-media-photos/${id}`
      );
      console.log(response); // Log the response for debugging
      if (response.status === 200) {
        message.success("Photo deleted successfully");
        console.log("Media image deleted successfully!");
        fetchUploadedImages(); // Fetch updated data after deletion
      } else {
        console.error("Failed to delete media image");
        message.error("Failed to delete media image");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const mediavideosDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-prashant.we-matter.xyz/api/v1/mediavideos/delete-media-videos/${id}`
      );
      console.log(response); // Log the response for debugging
      if (response.status === 200) {
        console.log("Media video deleted successfully!");
        message.success("Media video deleted successfully");
        fetchUploadedVideos(); // Fetch updated data after deletion
      } else {
        console.error("Failed to delete media video");
        message.error("Failed to delete media video");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchUploadedImages();
    fetchUploadedVideos();
  }, []);

  const handlevideoChange = (a) => {
    setVideoText(a.target.value);
  };

  const handleVideoUpload = async (a) => {
    a.preventDefault();

    if (!videotext.trim()) {
      setVideoError({
        title: "Text is required",
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://api-prashant.we-matter.xyz/api/v1/mediavideos/add-media-videos",
        {
          video: videotext,
        }
      );
      if (response.status === 201) {
        console.log("Data saved successfully!");
        message.success("Media video added successfully");
        setVideoText("");
        setVideoError({ title: "" });
        fetchUploadedVideos();
      } else {
        console.error("Failed to save data");
        message.error("Failed to delete media video");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      <h1>Media page</h1>
      <br />
      <form onSubmit={handlephotoUpload}>
        <div className="inputcard">
          <div className="formdata">
            <label>Position</label>
            <select
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            >
              <option value="">Select Position</option>
              <option value="One">One</option>
              <option value="Two">Two</option>
              <option value="Three">Three</option>
              <option value="Four">Four</option>
              <option value="Five">Five</option>
              <option value="Six">Six</option>
              <option value="Seven">Seven</option>
              <option value="Eight">Eight</option>
              <option value="Nine">Nine</option>
              <option value="Ten">Ten</option>
              <option value="Eleven">Eleven</option>
            </select>
            <div className="part2">
              <span className="errorclass" id="errorname">
                {positionerrors.position}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Description</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter Description"
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {descriptionerrors.description}
              </span>
            </div>
          </div>
          <div className="formdata">
            <label>Media Page Images</label>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {positionerrors.img}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button type="submit">Submit</button>
          </div>
        </div>
      </form>
      <h2>Media Page Images</h2>
      <table className="wholetable">
        <thead>
          <tr>
            <th>Position</th>
            <th>Image</th>
            <th>Description</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {images.map((item, index) => (
            <tr key={index}>
              <td>{item.position}</td>
              <td>
                <img
                  src={item.image}
                  alt={`Image ${index + 1}`}
                  className="fetch-img"
                />
              </td>
              <td>{item.description}</td> {/* Display description */}
              <td>
                <img
                  src={deleteicon}
                  onClick={() => mediaphotosDelete(item._id)}
                  alt="delete-icon"
                  className="icons"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
      <div className="dividerLine"></div>
      <br />
      <br />
      <form onSubmit={handleVideoUpload}>
        <div className="inputcard">
          <div className="formdata">
            <label>Media Page Video Iframe</label>
            <input
              type="text"
              id="videotext"
              value={videotext}
              onChange={handlevideoChange}
            />
            <div className="part2">
              <span className="errorclass" id="errorname">
                {videoerror.title}
              </span>
            </div>
          </div>
          <div className="formbutton">
            <button type="submit">Submit</button>
          </div>
        </div>
      </form>
      <h2>Media Page Videos</h2>
      <table className="wholetable">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Video</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {videos.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <iframe
                  width="300"
                  height="160"
                  src={`https://www.youtube.com/embed/${item.video}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </td>
              <td>
                <img
                  src={deleteicon}
                  onClick={() => mediavideosDelete(item._id)}
                  alt="delete-icon"
                  className="icons"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  );
};

export default Media;
